import {Helmet} from "react-helmet"
import shareImg from "../assets/images/meta/share.png"
import Background from "../components/Background/Background"
import mainHeroImg from "../assets/images/graphics/bookingHero.jpg"
import grayAreaImg from "../assets/images/graphics/grayArea.jpg"
import treeImg from "../assets/images/graphics/bookingTree.jpg"
import caveImg from "../assets/images/graphics/bookingCave.jpg"
import treeVideo from "../assets/video/tree.mp4"
import Nav from "../components/Nav/Nav"
import Footer from "../components/Footer/Footer"
import * as React from "react"

function track_stuff()
{
    if (window.gtag) window.gtag('event', 'conversion', {'send_to': 'AW-882281191/xJLhCJKlxIQDEOeV2qQD'});
}

const BookingPage = ({location}) => {
    return (
        <main>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Booking | Nocturne X</title>
                <meta name="description"
                      content="Enter an otherwordly forest of sound and light on planet Nocturne X in this transformative multi-sensory art experience, where the alien environment reacts to your every touch and movement."/>
                <meta property="og:image" content={shareImg}/>
                <link rel="canonical" href="https://nocturne-x.com"/>
            </Helmet>

            <Background/>

            <Nav />

            <div className="Hero">
                <img
                    src={mainHeroImg}
                    alt="Nocturne X"
                    className="Hero__graphic"
                />
                <h1 class="Text--yellow Text--center">Private & Corporate Event Rentals</h1>
                <h2><em>Booking now through January 7!</em></h2>
                <a
                    className="Button"
                    href="https://airtable.com/shrgVGUvBEYaNgo0k"
                    rel="noreferrer"
                    target="_blank"
                    onClick={()=>track_stuff()}
                >
                    Contact Us
                </a>
            </div>

            <div className="Section" id="main">
                <div class="container">
                    <p className="Text--section">
                        With a spacious dance floor, full bar, and lots of little nooks to explore, Nocturne X is an unforgettable party venue for everything from intimate gatherings to large events. With state-of-the-art sound systems and easy-to-access location in the heart of the Mission District in San Francisco, Nocturne X offers an inspiring and imaginative setting for anything you might dream up.  
                    </p>
                    
                    <div class="d-flex justify-content-center">.
                        
                        <ul>
                            <li>2400 square feet</li>
                            <li>300 person capacity</li>
                        </ul>
                        
                    </div>
                    
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <img
                                src={caveImg}
                                alt="Cave"
                                className="Section--img"
                            />  
                        </div>
                        <div class="col-12 col-lg-6">
                            <img
                                src={treeImg}
                                alt="Tree"
                                className="Section--img"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="Section" id="visualization">
                <div class="container">
                    <h1 class="Text--yellow Text--center">
                        Interactive surround sound visualization 
                    </h1>
                    <p className="Text--section">
                        Nocturne X offers unparalleled music visualization through a large-scale sound-reactive light installation that includes hundreds of hand-crafted LED sculptures for an awe-inspiring environment.
                    </p>
                    <video
                        type="video/mp4"
                        muted
                        autoPlay
                        loop
                        src={treeVideo}
                        style={{"width":"100%"}}
                    />
                </div>
            </div>


            <div className="Section"  id="grayarea">
                <div class="container">
                    <h1 class="Text--yellow Text--center">
                        Historic Grand Theater
                    </h1>
                    <p className="Text--section">
                    Nocturne X is located at <a href="#">Gray Area</a> in the historic Grand Theater, built in 1940. We offer modern sound, light, and visual capabilities, fiber internet, and full bar packages. The 10,000 square foot San Francisco landmark is a versatile venue available for performances, conferences, and exhibitions.
                    </p>
                    <img
                        src={grayAreaImg}
                        alt="Grand Theater"
                        className="Section--img"
                    />
                    <p className="Text--section">
                        Email us at <a href="mailto:events@grayarea.org">events@grayarea.org</a> for questions or other event inquiries
                    </p>
                </div>
            </div>
            
            <Footer />

        </main>
    )
}

export default BookingPage
